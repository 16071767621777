<!--
 * @Author: your name
 * @Date: 2021-01-26 14:42:18
 * @LastEditTime: 2021-01-26 14:56:33
 * @LastEditors: Please set LastEditors
 * @Description: 其他设置
 * @FilePath: \sd-vue-admin\src\views\setting\otherManagement\index.vue
-->
<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<script>
  export default {}
</script>

<style></style>
